export default {
  name: "Десерты",
  anchor: "deserti",
  items: [
    {
      name: "Наполеон",
      img: "/images/pizza/Наполеон 1.png",
      alt: "",
      desc: "Сливки 33%, мука, сливочное масло",
      price: 280,
      size: null,
    },
    {
      name: "Брауни",
      img: "/images/pizza/Брауни 1.png",
      alt: "",
      desc: "Торт брауни",
      price: 120,
      size: null,
    },
    {
      name: "Безе XL 2-штуки",
      img: "/images/photo_2023-12-25_18-24-06.jpg",
      alt: "",
      desc: "Классическое безе с мягкой начинкой, что еще о нем можно сказать.",
      price: 55,
      size: null,
    },
    {
      name: "Завиток, сгущенка",
      img: "/images/pizza/Завиток (сгущенка), завиток (грецкий орех), завиток (изюм) 1.png",
      alt: "",
      desc: "Нежное песочное тесто с вариантами начинок или украшений: грецкий орех, изюм, вареная сгущенка.",
      price: 25,
      size: null,
    },
    {
      name: "Корзинка",
      img: "/images/deserti/photo_2023-12-25_19-50-17.jpg",
      alt: "",
      desc: "Заварной крем со сливками",
      price: 120,
      size: null,
    },
    {
      name: "Фруктовая корзинка",
      img: "/images/deserti/photo_2023-12-25_19-50-28.jpg",
      alt: "",
      desc: "Основной состав:песочная основа,шапочка меренги. Начинка меняется,нужно уточнять.",
      price: 150,
      size: null,
    },
    {
      name: "Муравейник",
      img: "/images/deserti/photo_2023-12-25_19-50-26.jpg",
      alt: "",
      desc: "Муравейник",
      price: 80,
      size: null,
    },
    {
      name: "Медовое чудо",
      img: "/images/deserti/photo_2023-12-25_19-50-24.jpg",
      alt: "",
      desc: "Медовое чудо",
      price: 280,
      size: null,
    },
    {
      name: "Пани Валевска",
      img: "/images/deserti/photo_2023-12-25_19-50-26 (2).jpg",
      alt: "",
      desc: "ванильно-песочная основа,шапочка из меренг и Фруктовая прослойка меняется,нужно всегда уточнять.",
      price: 230,
      size: null,
    },
    {
      name: "Трубочка со взбитыми сливками",
      img: "/images/deserti/photo_2023-12-25_19-50-27 (2).jpg",
      alt: "",
      desc: "Трубочка со взбитыми сливками",
      price: 89,
      size: null,
    },
    {
      name: "Пирожное Картошка",
      img: "/images/deserti/photo_2023-12-25_19-50-27.jpg",
      alt: "",
      desc: "Набор 4 шт.",
      price: 250,
      size: null,
    },
    {
      name: "Торт Сникерс",
      img: "/images/deserti/photo_2023-12-25_19-50-29 (2).jpg",
      alt: "",
      desc: "Шоколадный бисквит,жареный арахис,крем чиз",
      price: 230,
      size: null,
    },
    {
      name: "Шоколадный чизкейк",
      img: "/images/deserti/photo_2023-12-25_19-50-29.jpg",
      alt: "",
      desc: "Шоколадный чизкейк",
      price: 160,
      size: null,
    },
    {
      name: "Синнабон",
      img: "/images/deserti/photo_2024-01-05_13-28-40.jpg",
      alt: "",
      desc: "",
      price: 160,
      size: null,
    },
  ],
};
