export default {
  name: "Кальцоне",
  anchor: "kalcone",
  items: [
    {
      name: "Кальцоне со свининой (белый соус)",
      img: "/images/pizza/Кальцоне с говядиной 1.png",
      alt: "",
      desc: "Белый соус, обжаренная свинина с луком, моцарелла.",
      price: 485,
      size: null,
    },
    {
      name: "Кальцоне со свининой (красный соус)",
      img: "/images/pizza/Кальцоне с говядиной 1.png",
      alt: "",
      desc: "Красный соус, обжаренная свинина с луком, моцарелла.",
      price: 485,
      size: null,
    },
    {
      name: "Кальцоне с курочкой (белый соус)",
      img: "/images/pizza/Кальцоне с говядиной 1.png",
      alt: "",
      desc: "Белый соус, обжаренная курочка, моцарелла.",
      price: 485,
      size: null,
    },
    {
      name: "Кальцоне с курочкой (красный соус)",
      img: "/images/pizza/Кальцоне с говядиной 1.png",
      alt: "",
      desc: "Красный соус, обжаренная курочка, моцарелла.",
      price: 485,
      size: null,
    },
    {
      name: "Реал кальцоне со свининой (красный соус)",
      img: "/images/pizza/Кальцоне2.jpg",
      alt: "",
      desc: "Ветчина, свежие шампиньоны, свинина, лук, соус красный, сыр, перец болгарский, томаты.",
      price: 595,
      size: null,
    },
    {
      name: "Реал кальцоне со свининой (белый соус)",
      img: "/images/pizza/КальцонеСБелымСоусом.jpg",
      alt: "",
      desc: "Ветчина, свежие шампиньоны, свинина, лук, соус белый, сыр, перец болгарский, томаты.",
      price: 595,
      size: null,
    },
    {
      name: "Реал кальцоне с курочкой (красный соус)",
      img: "/images/pizza/Кальцоне2.jpg",
      alt: "",
      desc: "Ветчина, свежие шампиньоны, курица, лук, соус красный, сыр, перец болгарский, томаты.",
      price: 595,
      size: null,
    },
    {
      name: "Реал кальцоне с курочкой (белый соус)",
      img: "/images/pizza/КальцонеСБелымСоусом.jpg",
      alt: "",
      desc: "Ветчина, свежие шампиньоны, курица, лук, соус белый, сыр, перец болгарский, томаты.",
      price: 595,
      size: null,
    },
  ],
};
