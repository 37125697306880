export default {
    name: "Напитки",
    anchor: "napitki",
    items: [
      {
        name: "Компот 0,5 л",
        img: "/images/pizza/Компот из сухофруктов 0,5 л 1.png",
        alt: "",
        desc: "Компот из сухофруктов",
        price: 110  ,
        size: null,
      },
      {
        name: "Морс 0,5 л",
        img: "/images/rolli/photo_2023-12-25_01-40-31.jpg",
        alt: "",
        desc: "Клюква",
        price: 110,
        size: null,
      },
      {
        name: "Кола ванила 0,5 л",
        img: "/images/photo_2024-02-04_21-53-47 (2).jpg",
        alt: "",
        desc: "Лимонад со вкусом ванили",
        price: 125,
        size: null,
      },
      {
        name: "Bonaqua 0,5 л",
        img: "/images/photo_2024-02-04_21-53-45.jpg",
        alt: "",
        desc: "Вода питьевая",
        price: 80,
        size: null,
      },
      {
        name: "Rich с лимоном 0,5 л",
        img: "/images/photo_2024-02-04_21-53-41.jpg",
        alt: "",
        desc: "Чай холодный",
        price: 115,
        size: null,
      },
      {
        name: "Rich зеленый 0,5 л",
        img: "/images/photo_2024-02-04_21-53-41 (2).jpg",
        alt: "",
        desc: "Чай холодный",
        price: 115,
        size: null,
      },
      {
        name: "Фанта 1л",
        img: "/images/photo_2024-02-04_21-53-43.jpg",
        alt: "",
        desc: "Лимонад с газом",
        price: 180,
        size: null,
      },
      {
        name: "Фанта 1л",
        img: "/images/photo_2024-02-04_21-53-47.jpg",
        alt: "",
        desc: "Лимонад с газом",
        price: 180,
        size: null,
      },
      {
        name: "Кола 0,5 л",
        img: "/images/photo_2024-02-04_21-53-51.jpg",
        alt: "",
        desc: "Лимонад с газом",
        price: 125,
        size: null,
      },
      {
        name: "Фанта 0,5 л",
        img: "/images/photo_2024-02-04_21-53-51 (2).jpg",
        alt: "",
        desc: "Лимонад с газом",
        price: 125,
        size: null,
      },
      {
        name: "Спрайт 0,5 л",
        img: "/images/photo_2024-02-04_21-53-50.jpg",
        alt: "",
        desc: "Лимонад с газом",
        price: 125,
        size: null,
      },
      {
        name: "Кола 0,33 л",
        img: "/images/photo_2024-02-04_21-53-45 (2).jpg",
        alt: "",
        desc: "Лимонад с газом",
        price: 95,
        size: null,
      },
      {
        name: "Фанта 0,33 л",
        img: "/images/photo_2024-02-04_21-53-44.jpg",
        alt: "",
        desc: "Лимонад с газом",
        price: 95,
        size: null,
      },
      {
        name: "Спрайт 0,33 л",
        img: "/images/photo_2024-02-04_21-53-46.jpg",
        alt: "",
        desc: "Лимонад с газом",
        price: 95,
        size: null,
      },
    ],
  };
  