export default {
  name: "Пицца и хачапури",
  anchor: "pizza",
  items: [
    {
      name: "Легкий перекус",
      img: "/images/pizza/Легкий перекус.jpg",
      alt: "",
      desc: "Пиццы Сырная, Маргарита, Дэлисия",
      price: [1380],
      size: [28],
    },
    {
      name: "Набор для двоих",
      img: "/images/pizza/переделанный.jpg",
      alt: "",
      desc: "Маргарита, Тиамо, Филадельфия, Кола `Добрый` 1л ",
      price: [1600],
      size: [28],
    },
    {
      name: "Мясной перекус",
      img: "/images/pizza/Мясной перекус.jpg",
      alt: "",
      desc: "Пиццы Тиамо, Пепперони, Паццио, Мачо",
      price: [2100],
      size: [28],
    },
    {
      name: "Большая Команда",
      img: "/images/pizza/Большая команда.jpg",
      alt: "",
      desc: "Пиццы Карбонара, Феличита, Папа хочет, Венеция, Тиамо",
      price: [3050],
      size: [33],
    },
    {
      name: "Мясное ассорти",
      img: "/images/pizza/мясное ассорти.jpg",
      alt: "",
      desc: "Моцарелла, бекон, шея, руляда куриная, карбонад, красный соус",
      price: [715],
      size: [33],
    },
    {
      name: "Грибная",
      img: "/images/pizza/грибная.jpg",
      alt: "",
      desc: "Моцарелла, лисички, опята, красный лук, белый соус",
      price: [699],
      size: [28],
    },
    {
      name: "Морская",
      img: "/images/pizza/морская.jpg",
      alt: "",
      desc: "Моцарелла, морской коктейль, красный лук, маслины, стружка тунца, белый соус",
      price: [705],
      size: [28],
    },
    {
      name: "Морская терияки",
      img: "/images/pizza/морская терияки.jpg",
      alt: "",
      desc: "Моцарелла, морской коктейль, красный лук, маслины, стружка тунца, белый соус, соус терияки",
      price: [710],
      size: [28],
    },
    {
      name: "Грушевая",
      img: "/images/pizza/грушевая.jpg",
      alt: "",
      desc: "Моцарелла, груша, мёд, белый соус",
      price: [495],
      size: [28],
    },
    {
      name: "Ягодная со сгущенкой",
      img: "/images/pizza/ягодная со сгущенкой.jpg",
      alt: "",
      desc: "Микс ягод со сгущенкой",
      price: [645],
      size: [28],
    },
    {
      name: "Ягодная с сыром",
      img: "/images/pizza/ягодная с сыром.jpg",
      alt: "",
      desc: "Микс ягод с моцареллой и сгущенкой, белый соус",
      price: [695],
      size: [28],
    },
    {
      name: "Shaverma c говядиной",
      img: "/images/pizza/шаверма1.jpg",
      alt: "",
      desc: "Моцарелла, чесночный соус, красный лук, черри, огурцы маринованные, говядина",
      price: [705],
      size: [33],
    },
    {
      name: "Шаверма c курицей",
      img: "/images/pizza/шаверма2.jpg",
      alt: "",
      desc: "Моцарелла, чесночный соус, красный лук, черри, огурцы маринованные, куриная грудка",
      price: [630],
      size: [33],
    },{
      name: "Тоскана",
      img: "/images/pizza/Тоскана.jpg",
      alt: "",
      desc: "Моцарелла, курица, ветчина, пепперони, фирменный белый соус",
      price: [525, 625],
      size: [28, 33],
    },
    {
      name: "Дэлисия",
      img: "/images/pizza/Дэлисия 1.png",
      alt: "",
      desc: "Моцарелла, пепперони, шампиньоны, маслины, красный соус",
      price: [515, 605],
      size: [28, 33],
    },
    {
      name: "Верона",
      img: "/images/pizza/фыв.jpg",
      alt: "",
      desc: "Моцарелла, курица, шапиньоны, ветчина, лучок, фирменный белый соус",
      price: [519, 615],
      size: [28, 33],
    },
    {
      name: "Соренто",
      img: "/images/pizza/Сорренто 1.png",
      alt: "",
      desc: "Моцарелла, курица, ветчина, охотничьи колбаски, фирменный белый соус",
      price: [515, 610],
      size: [28, 33],
    },
    {
      name: "Венеция терияки",
      img: "/images/pizza/Венеция терияки 1.png",
      alt: "",
      desc: "Моцарелла, картошечка, курица, лучок, соус Терияки, фирменный белый соус",
      price: [525, 620],
      size: [28, 33],
    },
    {
      name: "Венеция",
      img: "/images/pizza/Венеция 1.png",
      alt: "",
      desc: "Моцарелла, картошечка, курица, лучок, фирменный белый соус",
      price: [515, 610],
      size: [28, 33],
    },
    {
      name: "Маргарита",
      img: "/images/pizza/Маргарита 1.png",
      alt: "",
      desc: "Моцарелла, свежие томаты, красный соус",
      price: [485, 565],
      size: [28, 33],
    },
    {
      name: "Паццио",
      img: "/images/pizza/Паццио 1.png",
      alt: "",
      desc: "Моцарелла, бекон, лучок, курица, домашний соус Барбекю, красный соус",
      price: [535, 630],
      size: [28, 33],
    },
    {
      name: "Двойная пепперони",
      img: "/images/pizza/даблпеппа.jpg",
      alt: "",
      desc: "Моцарелла, пепперони, красный соус",
      price: [695],
      size: [33],
    },
    {
      name: "Пепперони",
      img: "/images/pizza/Пепперони 1.png",
      alt: "",
      desc: "Моцарелла, пепперони, красный соус",
      price: [515, 605],
      size: [28, 33],
    },
    {
      name: "Карбонара",
      img: "/images/pizza/Карбонара 1.png",
      alt: "",
      desc: "Моцарелла, бекон, желток, красный соус",
      price: [515, 610],
      size: [28, 33],
    },
    {
      name: "Тиамо",
      img: "/images/pizza/Тиамо 1.png",
      alt: "",
      desc: "Моцарелла, ветчина, курица, пепперони,охотничьи колбаски, красный соус",
      price: [535, 635],
      size: [28, 33],
    },
    {
      name: "Вегано",
      img: "/images/pizza/вегано.jpg",
      alt: "",
      desc: "Красный соус, моцарелла, свежие томаты, маслины, перец, лук, цукини.",
      price: [565],
      size: [33],
    },
    {
      name: "Феличита",
      img: "/images/pizza/Феличита 1.png",
      alt: "",
      desc: "Красный соус, моцарелла, свежие томаты, маслины, перец, лук, грибы.",
      price: [580],
      size: [33],
    },
    {
      name: "Палермо",
      img: "/images/pizza/Палермо 1.png",
      alt: "",
      desc: "Белый соус, моцарелла, свежие томаты, маслины, перец, лук, грибы.",
      price: [580],
      size: [33],
    },
    {
      name: "Примавера",
      img: "/images/pizza/Примавера 1.png",
      alt: "",
      desc: "Красный соус, моцарелла, свежие томаты, картошечка, маринованный огурчик, перец, лук, курица.",
      price: [575],
      size: [33],
    },
    {
      name: "Болонья",
      img: "/images/pizza/Болонья 1.png",
      alt: "",
      desc: "Белый соус, моцарелла, свежие томаты, картошечка, маринованный огурчик, перец, лук, курица.",
      price: [575],
      size: [33],
    },
    {
      name: "Мачо",
      img: "/images/pizza/macho.jpg",
      alt: "",
      desc: "Охотничьи колбаски, соус томатный, маринованные огурчики, тесто по особому рецепту, моцарелла, чеснок.",
      price: [585],
      size: [28],
    },
    {
      name: "Пьяченца",
      img: "/images/pizza/piach.jpg",
      alt: "",
      desc: "Тесто по особому рецепту, ветчина, шампиньоны свежие, моцарелла.",
      price: [595],
      size: [28],
    },
    {
      name: "Папа хочет",
      img: "/images/pizza/Папа хочет.jpg",
      alt: "",
      desc: "Охотничьи колбаски, красный соус, моцарелла, бекон, чеснок",
      price: [680],
      size: [28],
    },
    {
      name: "Папа хочет (острая)",
      img: "/images/pizza/ПапаХочетОстрая.jpg",
      alt: "",
      desc: "Охотничьи колбаски, красный соус, моцарелла, бекон, чеснок, перец халапеньо, перец чили",
      price: [690],
      size: [28],
    },
    {
      name: "Мясная",
      img: "/images/pizza/мясная.jpg",
      alt: "",
      desc: "Ржаное тесто, красный соус, моцарелла, курица маринованная, говядина маринованная, охотничьи колбаски.",
      price: [705],
      size: [33],
    },
    {
      name: "Сегале",
      img: "/images/pizza/сегале.jpg",
      alt: "",
      desc: "Ржаное тесто, красный соус, моцарелла, бекон, лук, чеснок.",
      price: [640],
      size: [33],
    },
    {
      name: "Акуто",
      img: "/images/pizza/акуто.jpg",
      alt: "",
      desc: "Ржаное тесто, красный соус, моцарелла, курица маринованная, ветчина, охотничьи колбаски, лук.",
      price: [650],
      size: [33],
    },
    {
      name: "Сырная на чеддере",
      img: "/images/pizza/сырнаячеддер.jpg",
      alt: "",
      desc: "Тесто по домашнему рецепту, сыр Имеретинский, сыр Сулугуни. сыр Чеддер.",
      price: [645],
      size: [33],
    },
    {
      name: "Сырная",
      img: "/images/pizza/сырная 1.jpg",
      alt: "",
      desc: "Тесто по домашнему рецепту, белый соус, сыр моцарелла.",
      price: [470, 550],
      size: [28, 33],
    },
    {
      name: "Гавайская",
      img: "/images/pizza/photo_2024-01-08_01-14-24.jpg",
      alt: "",
      desc: "Моцарелла, курица, ананасы, фирменный белый соус",
      price: [515, 610],
      size: [28, 33],
    },
    {
      name: "Прошутто с рукколой",
      img: "/images/pizza/photo_2024-01-08_01-14-30.jpg",
      alt: "",
      desc: "Моцарелла, ветчина, руккола, фирменный красный соус",
      price: [545],
      size: [28],
    },
    {
      name: "Пицца с лососем и рукколой",
      img: "/images/pizza/photo_2024-01-08_01-14-32.jpg",
      alt: "",
      desc: "Моцарелла , руккола, лосось, фирменный белый соус",
      price: [690],
      size: [28],
    },
    {
      name: "Пицца Формаджи",
      img: "/images/photo_2024-02-04_22-12-12.jpg",
      alt: "",
      desc: "Моцарелла, белый соус, сыр чеддер в борт, томаты, тесто по особому рецепту",
      price: [690],
      size: [28],
    },
    {
      name: "Хачапури по-грузински",
      img: "/images/pizza/Хачапури по-грузински 1.png",
      alt: "",
      desc: "Тесто по домашнему рецепту, сыр Имеретинский, сыр Сулугуни.",
      price: [560, 640],
      size: [28, 33],
    },
    {
      name: "Наггетсы + фри",
      img: "/images/pizza/photo_2024-01-08_01-14-25.jpg",
      alt: "",
      desc: "Картофель фри , куриные наггетсы",
      price: [250],
      size: null,
    },
  ],
};
