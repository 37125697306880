import pizza from "./pizza";
import specPizza from "./specPizza";
import udon from "./udon";
import sushi from "./sushi";
import mangal from "./mangal";
import kalcone from "./kalcone";
import chebureki from "./chebureki";
import deserti from "./deserti";
import napitki from "./napitki";

export const catalogList = [pizza, specPizza, kalcone, udon, sushi, mangal, chebureki, deserti, napitki];
