export default {
  name: "Мангал",
  anchor: "mangal",
  items: [
    {
      name: "Шашлык из куриного бедра",
      img: "/images/mangal/photo_2023-12-25_01-40-28.jpg",
      alt: "",
      desc: "Куриное бедро, маринованный лук, томатный соус (Порция от 200 гр).",
      price: 420,
      size: null,
    },
    {
      name: "Шашлык из свиной шейки",
      img: "/images/mangal/photo_2023-12-25_01-40-29.jpg",
      alt: "",
      desc: "Свинная шея, маринованный лук, томатный соус, Порция от 220 гр.",
      price: 480,
      size: null,
    },
    {
      name: "Шампиньоны гриль",
      img: "/images/mangal/photo_2023-12-25_01-40-27.jpg",
      alt: "",
      desc: "Шампиньоны гриль, порция 150 гр после ужарки",
      price: 250,
      size: null,
    },
    {
      name: "Овощи гриль",
      img: "/images/photo_2024-02-04_22-15-24.jpg",
      alt: "",
      desc: "Овощи гриль: перец, баклажан, помидор. Порция 250 гр после ужарки.",
      price: 350,
      size: null,
    },
  ],
};
