import React from "react";

function Text() {
  return (
    <section id="dostavka" className="text">
      <div className="text__content container">
        <h2>Условия доставки!</h2>
        <div>
          <p>
            Малое Верево, Большое Верево, Вайя, Верево Сити <br />
            <span>«доставка до 700 рублей платная 100 рублей, от 700 рублей бесплатная»</span> <br /><br />
            Микрорайон Аэродром, микрорайон Мариенбург, Пригородный, Торфяное, Тайцы <br />
            <span>«доставка до 1700 рублей платная 350 рублей, от 1700 рублей бесплатная» </span> <br /><br />
            Гатчина, Романовка, Новый Свет, Торфопредприятие, Горки, Перелево, Кирлово, Руссолово, Крайново, СНТ Мостновик, СНТ Скороход, ДМП Родник, Горки Лэнд (все номера), Бугры, СНТ Ижора, Вайялово <br /> 
            <span>«доставка до 1000 рублей платная 200 рублей, от 1000 рублей бесплатная»</span><br /><br />
            Колпаны, Химози, Д.Нижняя, Сокколово, Тихвинка, Парицы, Бор, Лукаши, Д.Котельниково, Дони, Хвойный <br /> 
            <span>«доставка до 2500 рублей платная 450 рублей, от 2500 рублей бесплатная»</span> <br /><br />
          </p>
        </div>
      </div>
    </section>
  );
}

export default Text;
