export default {
  name: "Суши, роллы",
  anchor: "sushi",
  items: [
    {
      name: "СЕТ №1",
      img: "/images/rolli/photo_2023-12-25_01-40-10.jpg",
      alt: "",
      desc: "Филадельфия, Канада, Маки с тунцом, Маки с лососем",
      price: 1400,
      size: null,
    },
    {
      name: "СЕТ №2",
      img: "/images/rolli/photo_2023-12-25_01-40-11.jpg",
      alt: "",
      desc: "Якудза, Сахара ролл, Темпура эби",
      price: 1300,
      size: null,
    },
    {
      name: "СЕТ №3",
      img: "/images/rolli/photo_2023-12-25_01-40-11 (2).jpg",
      alt: "",
      desc: "Ролл дракон, Канада, Маки с угрем, Ролл меркурий",
      price: 1700,
      size: null,
    },
    {
      name: "СЕТ №4",
      img: "/images/rolli/photo_2023-12-25_01-40-12.jpg",
      alt: "",
      desc: "Канада, Филадельфия, Эби темпура, Маки с угрем, Маки с лососем",
      price: 1800,
      size: null,
    },
    {
      name: "СЕТ №5",
      img: "/images/photo_2024-02-04_22-13-53.jpg",
      alt: "",
      desc: "Филадельфия, Эби лайт, Аляска, Спайси лосось, Запеченный угорь, Запеченный с креветкой, Запеченный лосось терияки",
      price: 2999,
      size: null,
    },
    {
      name: "Запеченный сет",
      img: "/images/rolli/photo_2023-12-25_01-40-02 (2).jpg",
      alt: "",
      desc: "Запеченный калифорния, Запеченный с креветкой, Темпура эби",
      price: 1495,
      size: null,
    },
    {
      name: "Канада",
      img: "/images/rolli/photo_2023-12-25_01-40-05.jpg",
      alt: "",
      desc: "Угорь, авокадо, огурцы, лосось, сыр креметта, кунжут.",
      price: 560,
      size: null,
    },
    {
      name: "Маки с авокадо",
      img: "/images/rolli/photo_2023-12-25_01-40-05 (2).jpg",
      alt: "",
      desc: "Рис, авокадо",
      price: 180,
      size: null,
    },
    {
      name: "Маки с лососем",
      img: "/images/rolli/photo_2023-12-25_01-40-05 (3).jpg",
      alt: "",
      desc: "Рис, лосось",
      price: 250,
      size: null,
    },
    {
      name: "Маки с огурцом",
      img: "/images/rolli/photo_2023-12-25_01-40-06.jpg",
      alt: "",
      desc: "Рис, огурец",
      price: 150,
      size: null,
    },
    {
      name: "Маки с тунцом",
      img: "/images/rolli/photo_2023-12-25_01-40-06 (2).jpg",
      alt: "",
      desc: "Рис, тунец",
      price: 260,
      size: null,
    },
    {
      name: "Маки с угрем",
      img: "/images/rolli/photo_2023-12-25_01-40-07.jpg",
      alt: "",
      desc: "Рис. угорь",
      price: 300,
      size: null,
    },
    {
      name: "Запеченный лосось",
      img: "/images/rolli/photo_2023-12-25_01-39-59 (2).jpg",
      alt: "",
      desc: "Рис, соус для запекания, сыр, тобико, сыр сливочный, лосось, огурец",
      price: 485,
      size: null,
    },
    {
      name: "Темпура эби",
      img: "/images/rolli/photo_2023-12-25_01-40-17 (2).jpg",
      alt: "",
      desc: "Креветка, огурец, сыр креметта, тобико, панировочные сухари",
      price: 445,
      size: null,
    },
    {
      name: "Фила люкс",
      img: "/images/rolli/photo_2023-12-25_01-40-20 (2).jpg",
      alt: "",
      desc: "Угорь, лосось, сыр Креметта, огурец свежий, соус унаги, кунжут",
      price: 550,
      size: null,
    },
    {
      name: "Фила премиум",
      img: "/images/rolli/photo_2023-12-25_01-40-21.jpg",
      alt: "",
      desc: "Лосось, сыр креметта, огурец, икра лососевая",
      price: 695,
      size: null,
    },
    {
      name: "Филадельфия",
      img: "/images/rolli/photo_2023-12-25_01-40-22.jpg",
      alt: "",
      desc: "Лосось, сыр креметта, огурец",
      price: 460,
      size: null,
    },
    {
      name: "Эби теммпура (креветка)",
      img: "/images/rolli/photo_2023-12-25_01-40-23.jpg",
      alt: "",
      desc: "Сыр креметта, масаго оранжевая, креветка темпура, кунжут",
      price: 395,
      size: null,
    },
    {
      name: "Якудза",
      img: "/images/rolli/photo_2023-12-25_01-40-23 (2).jpg",
      alt: "",
      desc: "Лосось, крем сыр, лук зеленый, панировочные сухарики",
      price: 400,
      size: null,
    },
    {
      name: "Аляска",
      img: "/images/rolli/photo_2023-12-25_01-39-58.jpg",
      alt: "",
      desc: "Рис, лосось, сыр сливочный, огурец, нори",
      price: 410,
      size: null,
    },
    {
      name: "Биг темпура",
      img: "/images/rolli/photo_2023-12-25_01-39-59.jpg",
      alt: "",
      desc: "Угорь, авокадо, кремета, спайси",
      price: 450,
      size: null,
    },
    {
      name: "Запеченный микс",
      img: "/images/rolli/photo_2023-12-25_01-40-01.jpg",
      alt: "",
      desc: "Рис, угорь, лосось, крабовый микс, кунжут",
      price: 450,
      size: null,
    },
    {
      name: "Калифорния",
      img: "/images/rolli/photo_2023-12-25_01-40-03.jpg",
      alt: "",
      desc: "Рис, крабовые палочки, майонез, огурец, тобико, нори",
      price: 400,
      size: null,
    },
    {
      name: "Ролл дракон",
      img: "/images/rolli/photo_2023-12-25_01-40-09.jpg",
      alt: "",
      desc: "Угорь, сыр, тобико, снежный краб, авокадо, соус спайси",
      price: 540,
      size: null,
    },
    {
      name: "Запеченный калифорния",
      img: "/images/rolli/photo_2023-12-25_01-40-00.jpg",
      alt: "",
      desc: "Крабовый микс, Тобико, Огурец, Соус для запекания",
      price: 440,
      size: null,
    },
    {
      name: "Запеченный с креветкой",
      img: "/images/rolli/photo_2023-12-25_01-40-02.jpg",
      alt: "",
      desc: "Рис, Сыр, Огурец, Креветка, Кунжут",
      price: 480,
      size: null,
    },
    {
      name: "Запеченный угорь",
      img: "/images/rolli/photo_2023-12-25_01-40-01 (2).jpg",
      alt: "",
      desc: "Рис, Сыр, Огурец, Угорь, Запеченный соус, Унаги, Кунжут",
      price: 550,
      size: null,
    },
    {
      name: "Калифорния лосось",
      img: "/images/rolli/photo_2023-12-25_01-40-04.jpg",
      alt: "",
      desc: "Лосось, майонез , сыр гауда, соус спайси, сыр креметта, тобико",
      price: 430,
      size: null,
    },
    {
      name: "Сахара ролл",
      img: "/images/rolli/photo_2023-12-24_20-49-17.jpg",
      alt: "",
      desc: "Снежный краб, тобико, угорь, майонез, авокадо, панировачные сухари",
      price: 470,
      size: null,
    },
    {
      name: "Калифорния с креветкой",
      img: "/images/rolli/photo_2023-12-24_20-49-15.jpg",
      alt: "",
      desc: "Креветки, огурец, сыр творожный, тобико",
      price: 450,
      size: null,
    },
    {
      name: "Спайси лосось",
      img: "/images/rolli/photo_2023-12-25_01-40-13.jpg",
      alt: "",
      desc: "Рис, огурец, соус спайси, лосось",
      price: 400,
      size: null,
    },
    {
      name: "Суши с креветкой",
      img: "/images/rolli/photo_2023-12-25_01-40-14.jpg",
      alt: "",
      desc: "Креветка, рис",
      price: 120,
      size: null,
    },
    {
      name: "Суши угорь",
      img: "/images/rolli/photo_2023-12-24_20-49-19.jpg",
      alt: "",
      desc: "Угорь, рис",
      price: 160,
      size: null,
    },
    {
      name: "Суши лосось",
      img: "/images/rolli/photo_2023-12-25_01-40-14 (2).jpg",
      alt: "",
      desc: "Лосось, рис",
      price: 140,
      size: null,
    },
    {
      name: "Сяке темпура",
      img: "/images/rolli/photo_2023-12-25_01-40-15 (2).jpg",
      alt: "",
      desc: "Рис, лосось, креветки в темпуре, огурец, сосус спайси, нори",
      price: 550,
      size: null,
    },
    {
      name: "Темпура с лососем",
      img: "/images/rolli/photo_2023-12-25_01-40-16.jpg",
      alt: "",
      desc: "Рис, сыр сливочный, огурец, лосось, масло, сухари панко, мука, нори",
      price: 490,
      size: null,
    },
    {
      name: "Темпура с тунцом",
      img: "/images/rolli/photo_2023-12-25_01-40-17.jpg",
      alt: "",
      desc: "Тунец, рис, огурец, сыр сливочный, кляр, сухари панко, масло, нори",
      price: 440,
      size: null,
    },
    {
      name: "Эби лайт",
      img: "/images/rolli/photo_2023-12-25_01-40-22 (2).jpg",
      alt: "",
      desc: "Рис, креветки, сыр, огурец",
      price: 460,
      size: null,
    },
    {
      name: "Запеченный лосось терияки",
      img: "/images/rolli/photo_2023-12-25_01-40-01 (2).jpg",
      alt: "",
      desc: "Рис, соус для запекания, сыр, тобико, сыр сливочный, жареный лосось, огурец, соус терияки, кунжут, нори",
      price: 480,
      size: null,
    },
    {
      name: "Мисо классик",
      img: "/images/rolli/GKtxQuOJYxg.jpg",
      alt: "",
      desc: "Мисо бульон, тофу, шитааке, вакамэ, лук зеленый",
      price: 170,
      size: null,
    },
    {
      name: "Мисо острый",
      img: "/images/rolli/GKtxQuOJYxg.jpg",
      alt: "",
      desc: "Мисо бульон, тофу, шитааке, вакамэ, лук зеленый, свинина жареная",
      price: 210,
      size: null,
    },
    {
      name: "Салат чука",
      img: "/images/rolli/photo_2023-12-25_18-51-56.jpg",
      alt: "",
      desc: "Чука, ореховый соус (От 180гр)",
      price: 150,
      size: null,
    },
    // {
    //   name: "Ролл сэт",
    //   img: "/images/rolli/Ролл сэт 1650.jpg",
    //   alt: "",
    //   desc: "Ролл крабб, ролл креветка, ролл курица, ролл лосось, сливочный лосось, сливочный угорь, калифорния лосось, филадельфия",
    //   price: 1650,
    //   size: null,
    // },
  ],
};
