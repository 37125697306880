export default {
  name: "Чебуреки",
  anchor: "chebureki",
  items: [
    {
      name: "Чебурек (баранина)",
      img: "/images/pizza/Чебурек (баранина) 1.png",
      alt: "",
      desc: "Нежное тесто по старинному домашнему рецепту, фарш говядина/ баранина, лучок.",
      price: 165,
      size: null,
    },
    {
      name: "Чебурек (свинина)",
      img: "/images/pizza/Чебурек (свинина) 1.png",
      alt: "",
      desc: "Нежное тесто по старинному домашнему рецепту, фарш говядина/ свинина, лучок.",
      price: 135,
      size: null,
    },
    {
      name: "Чебурек (сыр)",
      img: "/images/pizza/Чебурек (сыр) 1.png",
      alt: "",
      desc: "Нежное тесто по старинному домашнему рецепту, сыр.",
      price: 165,
      size: null,
    },
  ],
};
