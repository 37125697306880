export default {
  name: "Спецпицца",
  anchor: "specPizza",
  items: [
    {
      name: "Слежу за тобой",
      img: "/images/pizza/Слежу за тобой 1.png",
      alt: "",
      desc: "Моцарелла, курица, оливки, красный соус, соус барбекю, белый соус (вес 1.25 кг)",
      price: [1560],
      size: [45],
    },
    {
      name: "Похудейка",
      img: "/images/pizza/Похудейка 1.png",
      alt: "",
      desc: "Моцарелла, ветчина, курица, пепперони, охотничьи колбаски, красный соус. (вес 2 кг)",
      price: [2340],
      size: [45],
    },
    {
      name: "Прости за опоздание",
      img: "/images/pizza/Прости за опоздание 1.png",
      alt: "",
      desc: "Моцарелла, курица, пепперони, охотничьи колбаски, соус барбекю, оливки, белый соус (вес 1.25 кг)",
      price: [1560],
      size: [45],
    },
    {
      name: "Улыбнись",
      img: "/images/pizza/Улыбнись 1.png",
      alt: "",
      desc: "Моцарелла, ветчина, бекон, пепперони, соус барбекю, черри, белый соус (вес 1.25 кг)",
      price: [1560],
      size: [45],
    },
    {
      name: "Позвони мне",
      img: "/images/pizza/Позвони мне 1.png",
      alt: "",
      desc: "Моцарелла, курица, пепперони, охотничьи колбаски, красный соус (вес 1.25 кг)",
      price: [1560],
      size: [45],
    },
    {
      name: "Моя любовь",
      img: "/images/pizza/Моя любовь 1.png",
      alt: "",
      desc: "Моцарелла, ветчина, охотничьи колбаски, белый соус, сливки (вес 1.25 кг)",
      price: [1560],
      size: [45],
    },
    {
      name: "Пойдем в кино",
      img: "/images/pizza/Пойдем в кино 1.png",
      alt: "",
      desc: "Моцарелла, ветчина, пепперони, грибы, соус барбекю, красный соус, чипсы (вес 1.25 кг)",
      price: [1560],
      size: [45],
    },
    {
      name: "Вырви глаз",
      img: "/images/pizza/Вырви глаз 1.png",
      alt: "",
      desc: "Моцарелла, ветчина, курица, пепперони, красный соус, соус барбекю (вес 1.25 кг)",
      price: [1560],
      size: [45],
    },
  ],
};
